<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="{spf: form.spf}"
                               @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-grey">3</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="data.item.itemToDelete"
                            @input="e => { if (!e) selectAll = false; }"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #cell(name_ru)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.isDouble }">
                        <b-form-input v-if="variantAttribute"
                                      class="text-left"
                                      style="min-width: 90%"
                                      :value="data.item.name_ru"
                                      @change="onChangeName($event, data)"
                                      @keyup.enter.exact="keyup13">
                        </b-form-input>
                        <div v-else>{{ data.value }}</div>
                        <template v-if="data.item.isDouble">
                            <i
                                class="icon icon-danger"
                                @mouseover="data.item.showDoubleMsg = true"
                                @mouseleave="data.item.showDoubleMsg = false"></i>
                            <div v-show="data.item.showDoubleMsg" class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                        <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.name_ru === null || data.item.name_ru === '')">Должно быть заполнено!</p>
                    </div>
                </template>
                <template #cell(cost_amount)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  v-model="data.item.cost_amount"
                                  :disabled="nameIsEmpty(data.item, 'name_ru')"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixed(data.item, 'cost_amount', data.item.cost_amount, 2, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                    <p class="goods-form-val-msg" v-if="variantAttribute && (data.item.cost_amount === null || data.item.cost_amount === '' || data.item.cost_amount <= 0)">Должно быть > 0!</p>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                            <template v-slot:button-content>
                                <i class="icon icon-more"></i>
                            </template>
                            <template>
                                <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                    Добавить файл(ы)
                                </b-dropdown-item>
                                <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                    Удалить
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </div>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="2">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "../mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from '../components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "../components/forms-download-reprt.vue";

export default {
    name: 'Form311',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, BreadcrumbsFilter, FormsDownloadReprt, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            defaultParams: {
                gr: 13,
                abp: 266,
                prg: 10,
                ppr: 15
            },
            form: {
                code: '311',
                name_ru: 'Расчет расходов на субсидии юридическим лицам, в том числе крестьянским (фермерским хозяйствам)',
                name_kk: 'Заңды тұлғаларға, оның ішінде шаруа шаруашыларына (фермалық шаруашылықтар) субсидиялар бойынша шығындарды есептеу',
                spf: {
                    name: '311-Субсидии физическим и юридическим лицам, в том числе крестьянским (фермерским) хозяйствам',
                    spf: '311'
                }
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'cost_amount',
                    label: 'Сумма (тыс.тенге)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        addItem() {
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);
            this.budgetForm.push(item);
            this.checkForDoubles(this.budgetForm, 'name_ru');
        },
        async itemUpdate(item) {
            this.$set(item, 'name_ru', 'Субсидирование процентной ставки по кредитам в рамках Государственной программы поддержки и развития бизнеса «Дорожная карта бизнеса - 2025»');
            this.$set(item, 'cost_amount', 0);
            this.$set(item, 'files', 0);
            this.$set(item, 'isDouble', false);
            this.$set(item, 'showDoubleMsg', false);
            this.$set(item, 'itemToDelete', false);
            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/${JSON.stringify(this.header)}`);
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        calcTotal(ids) {
            this.$set(this.header, 'value', this.calcTotalForDelete(this.budgetForm, 'cost_amount', ids));
        },

        afterDeleted() {
            this.checkForDoubles(this.budgetForm, 'name_ru');
        },

        download() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса download()', error.toString());
                }
            );
        },

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= 12');
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        async loadDatas() {
            this.load = true;
            await this.budgetForm.splice(0);
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                const values = await response.json();

                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        name_ru: val.name_ru,
                        cost_amount: val.cost_amount,
                        files: val.files,
                        row_files: val.row_files,
                        isDouble: false,
                        showDoubleMsg: false,
                        itemToDelete: false,
                    };

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
            }
            this.load = false;
        },

        noAbc: function (event) {
            // const regex = new RegExp('^-?[0-9]+$');\.?
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        prepareForSave() {
            const values = [];
            for (const row of this.budgetForm) {
                if (this.isSavingForbidden(row, this.tableFields, ['cost_amount'], ['name_ru'])) return;
                const item = Object.assign({}, this.header);
                this.$set(item, 'id', row.id);
                this.$set(item, 'name_ru', row.name_ru);
                this.$set(item, 'cost_amount', parseFloat(row.cost_amount));
                this.$set(item, 'row_files', row.row_files);

                values.push(item);
            }
            if (values.length > 0) {
                this.$bvModal.msgBoxConfirm(
                    'Вы действительно хотите сохранить данные?',
                    {
                        title: 'Подтверждение',
                        size: 'lg',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        okTitle: 'Да',
                        cancelTitle: 'Отмена',
                        footerClass: 'p-2',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then(value => {
                        if (value && this.variantAttribute) {
                            this.save(values, this.form.code);
                        }
                    })
                    .catch(error => {
                        this.makeToast('danger', 'Ошибка сохранения', error.toString());
                    });
            }
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        async save(values, numberOfForm) {
            this.isLoad = true;
            try {
                this.$set(this.header, 'value', this.total);
                const response = await fetch('/api-py/save-br-two-column-form-with-row-attach-files/' + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDatas();
                } else {
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                const response = await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // this.makeToast('success', 'Сообщение', 'Данные сохранены');
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_amount);
            }
            return Math.ceil(sum);
        }
    }
};
</script>
<style scoped>
.goods-form-val-msg {
    font-size: 0.5rem;
    font-style: italic;
    color: red;
}
.error .pop-up, .more-info .pop-up {
    opacity: 1;
    visibility:visible;
}
.error input {
    padding-left: 25px;
}
.filter-actions-flex {
    display: flex;
}
</style>